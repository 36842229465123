import axios from '@/axios.js'

const state = () => ({
    kejadian_tempats: []
})

const mutations = {
    SET_KEJADIANTEMPATS (state, payload) {
      state.kejadian_tempats = payload
    },
    ADD_KEJADIANTEMPAT (state, item) {
      state.kejadian_tempats.unshift(item)
    },
    UPDATE_KEJADIANTEMPAT (state, kejadian_tempat) {
      const kejadian_tempatIndex = state.kejadian_tempats.findIndex((p) => p.id === kejadian_tempat.id)
      Object.assign(state.kejadian_tempats[kejadian_tempatIndex], kejadian_tempat)
    },
    REMOVE_KEJADIANTEMPAT (state, itemId) {
      const ItemIndex = state.kejadian_tempats.findIndex((p) => p.id === itemId)
      state.kejadian_tempats.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchKejadianTempat ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/kejadian_tempat`)
        .then((response) => {
          commit('SET_KEJADIANTEMPATS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addKejadianTempat ({ commit }, kejadian_tempat ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', kejadian_tempat)
        .then((response) => {
          commit('ADD_KEJADIANTEMPAT', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateKejadianTempat ({ commit }, kejadian_tempat) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kejadian_tempat.id}`, kejadian_tempat)
        .then((response) => {
            commit('UPDATE_KEJADIANTEMPAT', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormKejadianTempat ({ commit }, kejadian_tempat) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, kejadian_tempat)
        .then((response) => {
            commit('UPDATE_KEJADIANTEMPAT', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeKejadianTempat ({ commit }, kejadian_tempat) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${kejadian_tempat.id}`)
        .then((response) => {
            commit('REMOVE_KEJADIANTEMPAT', kejadian_tempat.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}